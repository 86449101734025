<template>
  <div>
    <section
      class="input-unit"
      :class="{
        'hide-border': hideBorder,
        'unset-max-width': unsetMaxWidth,
        bold: bold
      }"
    >
      <input :type="type" :value="value" class="input-free-text-component" :placeholder="placeholder" :class="{ 'averta-bold': textBold }" :disabled="disabled" />
    </section>
  </div>
</template>

<script>
export default {
  name: 'InputComponents',
  props: {
    hideBorder: {
      type: Boolean,
      default: false
    },
    textBold: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    unsetMaxWidth: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.input-free-text-component {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid $color-grey-2 !important;
  font-size: 15px;
}
</style>
